import loadable from '@loadable/component';

export default {
  ProfileMenu: loadable(() => import(/*webpackChunkName: "profile"*/ './menu')),
  ProfileNewOrderButton: loadable(() => import(/*webpackChunkName: "profile"*/ './newOrderButton')),
  ProfileUserGreetingMessage: loadable(() => import(/*webpackChunkName: "profile"*/ './userGreetingMessage')),
  ProfileAccountInformation: loadable(() => import(/*webpackChunkName: "profile"*/ './accountInformation')),
  ProfileRecentOrders: loadable(() => import(/*webpackChunkName: "profile"*/ './recentOrders')),
  ProfileNewProspectButton: loadable(() => import(/*webpackChunkName: "profile"*/ './newProspectButton')),
  ProfileBackLink: loadable(() => import(/*webpackChunkName: "profile"*/ './backLink')),
  ProfileDocumentFilter: loadable(() => import(/*webpackChunkName: "profile"*/'./documentFilter')),
  ProfileOrderList: loadable(() => import(/*webpackChunkName: "profile"*/'./orderList')),
  ProfileQuoteList: loadable(() => import(/*webpackChunkName: "profile"*/'./quoteList')),
  ProfileInvoiceList: loadable(() => import(/*webpackChunkName: "profile"*/'./invoiceList')),
  ProfileReturnOrderList: loadable(() => import(/*webpackChunkName: "profile"*/'./returnOrderList')),
  ProfileNewReturnOrderButton: loadable(() => import(/*webpackChunkName: "profile"*/'./newReturnOrderButton')),
  ProfileReturnReceiptList: loadable(() => import(/*webpackChunkName: "profile"*/'./returnReceiptList')),
  ProfileCreditNoteList: loadable(() => import(/*webpackChunkName: "profile"*/'./creditNoteList')),
  ProfileShipmentList: loadable(() => import(/*webpackChunkName: "profile"*/'./shipmentList')),
  ProfileOrderAuthorizationFilter: loadable(() => import(/*webpackChunkName: "order-auth"*/'./orderAuthorizationFilter')),
  ProfileOrderAuthorizationList: loadable(() => import(/*webpackChunkName: "order-auth"*/'./orderAuthorizationList')),
  ProfileSalesAgreementFilter: loadable(() => import(/*webpackChunkName: "sales-agr"*/'./salesAgreementFilter')),
  ProfileSalesAgreementList: loadable(() => import(/*webpackChunkName: "sales-agr"*/'./salesAgreementList')),
};

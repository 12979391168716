import { NAVIGATED } from 'behavior/routing';
import { UNLOCK_FORM_SUBMIT, POST_FORM } from './actions';

/*[179234][Woom] 5.1.Facilitate Pre - Ordering with Quotations*/
const initialState = {
  metaTitle: null,
  metaDescription: null,
  isPreOrderPage: null,
  content: null, // Array
  omitScroll: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NAVIGATED:
      const page = action.payload.page;
      if (!page || page === state)
        return state;

      page.loadedTime = Date.now();

      return page;
    case UNLOCK_FORM_SUBMIT:
      return { ...state, forms: { ...state.forms, [action.payload]: {} } };
    case POST_FORM:
      return { ...state, postForm: action.payload };
    default:
      return state;
  }
}
import content from 'components/objects/content';
import product from 'components/objects/product';
import productList from 'components/objects/productList/page';
import productGroupPage from 'components/objects/productGroup';
import search from 'components/objects/productList/search';
import plpWithCategory from 'components/objects/productList/withCategory';
import faq from 'components/objects/faq';
import news from 'components/objects/news';
import system from 'components/objects/systemPages';
import error from 'components/objects/error/page';
import basket from 'components/objects/basket';
import offline from 'components/objects/offlineMode/page';
import productComparison from 'components/objects/productComparison/page';
import closedStoreLogin from 'components/objects/closedStore/login';
import closedStoreForgotPassword from 'components/objects/closedStore/forgotPassword';
import closedStoreResetPassword from 'components/objects/closedStore/resetPassword';
import wishList from 'components/objects/wishList';
import contactUs from 'components/objects/contactUs';
import visualDesigner from 'components/objects/visualDesigner';
import forgotPassword from 'components/objects/forgotPassword';
import resetPassword from 'components/objects/resetPassword';
import login from 'components/objects/login';
import blogItem from 'components/objects/blogItem';
import blog from 'components/objects/blog';
import myAccount from 'components/objects/profile/myAccount';
import documents from 'components/objects/profile/documents';
import orderAuthorizations from 'components/objects/profile/orderAuthorizations';
import salesAgreements from 'components/objects/profile/salesAgreements';
import createDocFreeReturnOrder from 'components/objects/profile/createDocFreeReturnOrder';
import createDocBasedReturnOrder from 'components/objects/profile/createDocBasedReturnOrder';
import registration from 'components/objects/registration';
import changePassword from 'components/objects/profile/changePassword';
import createProspect from 'components/objects/profile/createProspect';
import subAccounts from 'components/objects/profile/subAccounts';

const defaultPageRenderers = {
  ...content,
  ...product,
  ...productList,
  ...productGroupPage,
  ...search,
  ...plpWithCategory,
  ...faq,
  ...news,
  ...system,
  ...error,
  ...basket,
  ...offline,
  ...productComparison,
  ...closedStoreLogin,
  ...closedStoreForgotPassword,
  ...closedStoreResetPassword,
  ...wishList,
  ...contactUs,
  ...visualDesigner,
  ...forgotPassword,
  ...resetPassword,
  ...login,
  ...blogItem,
  ...blog,
  ...myAccount,
  ...documents,
  ...orderAuthorizations,
  ...salesAgreements,
  ...createDocFreeReturnOrder,
  ...createDocBasedReturnOrder,
  ...registration,
  ...changePassword,
  ...createProspect,
  ...subAccounts,
};

export default defaultPageRenderers;

import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { exhaustMap, mergeMap, takeUntil } from 'rxjs/operators';
import {
  BlogItemPageAction,
  commentProcessed,
  commentsReceived,
  BLOG_COMMENTS_REQUESTED,
  BLOG_COMMENT_SUBMITTED,
} from './actions';
import { addCommentMutation, commentsQuery } from './queries';
import type { BlogComment } from './types';
import { LOCATION_CHANGED } from 'behavior/events';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormName } from 'behavior/pages';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { EMPTY, merge, of } from 'rxjs';

const blogItemEpic: Epic<BlogItemPageAction> = (action$, _, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const commentsRequested$ = action$.pipe(
    ofType(BLOG_COMMENTS_REQUESTED),
    exhaustMap(action => api.graphApi<BlogCommentsResponse>(commentsQuery, action.payload).pipe(
      mergeMap(({ pages: { blogItemPage } }) => {
        if (!blogItemPage)
          return EMPTY;

        return of(commentsReceived(blogItemPage.blogItem.comments.items));
      }),
      takeUntil(locationChanged$),
    )),
  );

  const commentSubmitted$ = action$.pipe(
    ofType(BLOG_COMMENT_SUBMITTED),
    exhaustMap(action => api.graphApi(addCommentMutation, { input: action.payload }).pipe(
      mergeMap(() => [commentProcessed(), resetCaptcha(FormName.Comment), unlockForm(FormName.Comment)]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(FormName.Comment), unlockForm(FormName.Comment))),
      retryWithToast(action$, logger, _ => of(resetCaptcha(FormName.Comment), unlockForm(FormName.Comment))),
      takeUntil(locationChanged$),
    )),
  );

  return merge(commentsRequested$, commentSubmitted$);
};

export default blogItemEpic;

type BlogCommentsResponse = {
  pages: {
    blogItemPage: {
      blogItem: {
        comments: {
          items: BlogComment[];
        };
      };
    } | null;
  };
};
